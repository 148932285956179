const theme = {
  font: {
    display: `'Futura PT', sans`,
    primary: `'Futura PT', sans`,
    secondary: `'IBM Plex Sans', sans-serif`,
    emph: `'IBM Plex Sans', sans-serif`,
  },
  font_size: {
    small: "0.8rem",
    regular: "1rem",
    body: "1rem",
    h1: "3.052rem",
    h2: "2.411rem",
    h3: "1.953rem",
    h4: "1.563rem",
    h5: "1.25rem",
    large: "1.25rem",
    larger: "1.563rem",
    xlarge: "1.953rem",
  },
  line_height: {
    small: "30px",
    regular: "32px",
    large: "40px",
    larger: "48px",
    xlarge: "56px",
  },
}

export const screen = {
  xs: "575px",
  sm: "767px",
  md: "991px",
  lg: "1199px",
}

export const colors = {
  light: {
    primary: "hsla(90, 14%, 97%, 1)",
    secondary: "hsla(60, 6%, 88%, 1)",
    accent: "hsla(79, 41%, 60%, 1)",
    highlight: "hsla(350, 77%, 23%, 1)",
    text: "hsla(76, 35%, 8%, 1)",
    text_light: "hsla(80, 14%, 21%, 1)",
    theme: "1",
  },
  dark: {
    primary: "hsl(225, 6%, 13%)",
    secondary: "hsla(60, 2%, 20%, 1)",
    accent: "hsla(85, 100%, 13%, 1)",
    highlight: "hsla(0, 88%, 85%, 1)",
    text: "hsl(216, 12%, 92%)",
    text_light: "hsla(77, 12%, 69%, 1)",
    theme: "0",
  },
}

export default theme
