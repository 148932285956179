import moment from "dayjs"

export const formatDate = date => moment(date).format("DD MMM YYYY")

export const getCurrentTheme = () =>
  localStorage.getItem("COLOR_THEME") ||
  (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light")

export const attachToRootStyle = (prefix, styles) => {
  const root = window.document.documentElement
  Object.entries(styles).forEach(([key, value]) => {
    root.style.setProperty(
      `--${prefix.replace("_", "-")}-${key.replace("_", "-")}`.trim(),
      value.toString().trim()
    )
  })
}
