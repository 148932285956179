import theme, { colors } from "./src/styles/theme"
import { attachToRootStyle, getCurrentTheme } from "./src/utils"

export const onClientEntry = () => {
  window.onload = e => {
    const currentColorTheme = getCurrentTheme()
    attachToRootStyle("color", colors[currentColorTheme])
    // The following assumes only one level of nesting
    Object.entries(theme).forEach(([key, value]) =>
      attachToRootStyle(key, value)
    )
  }
}
